import axios from "../instance";

export default {
    getAll: (companyId) => {
        if (companyId) {
            return axios.api.get(`/branchs?companyId=${companyId}`).then((response) => response.data);
        } else {
            return axios.api.get(`/branchs`).then((response) => response.data);
        }
    },
    searchquery: (companyId, code) => {
        return axios.api.get(`/branchs?companyId=${companyId}&code=${code}`).then((response) => response.data);

    },
    getOne: (id) => {
        return axios.api.get(`/branchs/${id}`).then((response) => response.data);
    },
    search: (body) => {
        return axios.api.post("/branchs/search", body).then((response) => response.data);
    },
    create: (body) => {
        return axios.api.post("/branchs", body).then((response) => response.data);
    },
    update: (id, body) => {
        return axios.api.put(`/branchs/${id}`, body).then((response) => response.data);
    },
    delete: (id) => {
        return axios.api.delete(`/branchs/${id}`).then((response) => response.data);
    }
};
