import axios from "../instance";

export default {
    getAll: (branchId) => {
        if (branchId) {
            return axios.api.get(`/warehouses?branchId=${branchId}`).then((response) => response.data);
        } else {
            return axios.api.get(`/warehouses?companyId=${
                localStorage.getItem('companyId')
            }`).then((response) => response.data);
        }
    },
    getOne: (id) => {
        return axios.api.get(`/warehouses/${id}`).then((response) => response.data);
    },
    search: (body) => {
        return axios.api.post(`/warehouses/search`, body).then((response) => response.data);
    },
    create: (body) => {
        return axios.api.post("/warehouses", body).then((response) => response.data);
    },
    update: (id, body) => {
        return axios.api.put(`/warehouses/${id}`, body).then((response) => response.data);
    },
    delete: (id) => {
        return axios.api.delete(`/warehouses/${id}`).then((response) => response.data);
    },
    getProductBywarehouseId: (warehouseId, productId) => {
        if (productId) {
            return axios.api.get(`/warehouseStocks/?warehouseId=${warehouseId}&productId=${productId}`).then((response) => response.data);
        } else {
            return axios.api.get(`/warehouseStocks/?warehouseId=${warehouseId}`).then((response) => response.data);
        }
    },

    getProductAvaliableBywarehouse: (warehouseId) => {
        return axios.api.get(`/warehouseStocks/?warehouseId=${warehouseId}`).then((response) => response.data);
    },

    getProductByvanId: (warehouseId, productId) => {
        return axios.api.get(`/vanStocks/?vanId=${warehouseId}&productId=${productId}`).then((response) => response.data);
    },
    getProductByvan: (warehouseId) => {
        return axios.api.get(`/vanStocks/?vanId=${warehouseId}`).then((response) => response.data);
    },


    updatestock: (body) => {
        return axios.api.put(`/warehouseStocks`, body).then((response) => response.data);
    },
    updateVAN: (body) => {
        return axios.api.put(`/vanStocks`, body).then((response) => response.data);
    },
    updateVANDIRECT: (body) => {
        return axios.api.put(`/vanStocks/updateDirectVan`, body).then((response) => response.data);
    },
    updateVANtoVAN: (body) => {
        return axios.api.put(`/vanStocks/updateVanToVan`, body).then((response) => response.data);
    }
};
