import axios from "../instance";

export default {
    getAll: (companyId) => {
        return axios.api.get(`/companies`).then((response) => response.data);
        // if (companyId) {
        //     return axios.api.get(`/companies?companyId=${companyId}`).then((response) => response.data);
        // } else {
        //     return axios.api.get(`/companies`).then((response) => response.data);
        // }
    },
    getOne: (id) => {
        return axios.api.get(`/companies/${id}`).then((response) => response.data);
    },
    search: (body) => {
        return axios.api.post("/companies/search", body).then((response) => response.data);
    },
    create: (body) => {
        return axios.api.post("/companies", body).then((response) => response.data);
    },
    update: (id, body) => {
        return axios.api.put(`/companies/${id}`, body).then((response) => response.data);
    },
    delete: (id) => {
        return axios.api.delete(`/companies/${id}`).then((response) => response.data);
    }
};
