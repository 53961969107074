import axios from "../instance";

export default {
    getAll: (companyId) => {
        if (companyId) {
            return axios.api.get(`/productUnits?companyId=${companyId}`).then((response) => response.data);
        } else {
            return axios.api.get(`/productUnits`).then((response) => response.data);
        }
    },
    getUnit: (code,companyId) => {
        return axios.api.get(`/productUnits?code=${code}&companyId=${companyId}`).then((response) => response.data);
    },
    getOne: (id) => {
        return axios.api.get(`/productUnits/${id}`).then((response) => response.data);
    },
    search: (body) => {
        return axios.api.post("/productUnits/search", body).then((response) => response.data);
    },
    create: (body) => {
        return axios.api.post("/productUnits", body).then((response) => response.data);
    },
    update: (id, body) => {
        return axios.api.put(`/productUnits/${id}`, body).then((response) => response.data);
    },
    delete: (id) => {
        return axios.api.delete(`/productUnits/${id}`).then((response) => response.data);
    }
};
