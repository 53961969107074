import axios from "../instance";

export default {
    getAll: (companyId) => {
        if (companyId) {
            return axios.api.get(`/priceLists?companyId=${companyId}`).then((response) => response.data);
        } else {
            return axios.api.get("/priceLists").then((response) => response.data);
        }
    },
    getOne: (id, companyId) => {
        return axios.api.get(`/priceLists/findOneByCompany?id=${id}&companyId=${companyId}`).then((response) => response.data);
    },
    search: (body) => {
        return axios.api.post("/priceLists/search", body).then((response) => response.data);
    },
    searchItem: (body) => {
        return axios.api.post("/priceListItems/search", body).then((response) => response.data);
    },
    create: (body) => {
        return axios.api.post("/priceLists", body).then((response) => response.data);
    },
    update: (id, body) => {
        return axios.api.put(`/priceLists/${id}`, body).then((response) => response.data);
    },
    updateItem: (id, body) => {
        return axios.api.put(`/priceListItems/${id}`, body).then((response) => response.data);
    },
    postItem: (body) => {
        return axios.api.post(`/priceListItems`, body).then((response) => response.data);
    },
    deleteItem: (id) => {
        return axios.api.delete(`/priceListItems/${id}`).then((response) => response.data);
    },
    delete: (id) => {
        return axios.api.delete(`/priceLists/${id}`).then((response) => response.data);
    }
};
